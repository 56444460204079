// AB228 - delete this file when test is over
import { inject, Injectable } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { _round } from '@naturalcycles/js-lib'
import { Experiment, getBucket, isThermometerKey, ProductKey } from '@naturalcycles/shared'
import { TranslateService } from '@ngx-translate/core'
import { EnrichedProduct } from '@src/app/shared/typings/interfaces/products'
import { env } from '@src/environments/environment'
import { skipWhile } from 'rxjs'
import { SignalStore } from '../store/signalStore'

/*
Control: Regular sign up with quiz
Test: Oura/AW/Therm without quiz
Test2: Oura/AW/Band/Therm without quiz
Test3: Oura/AW/Ring/Therm without quiz
*/

export enum BackdoorTestBucket {
  control = 'control',
  test = 'test',
  test2 = 'test2',
  test3 = 'test3',
}

export enum BackdoorDevice {
  NC_BAND = 'NC° Band',
  NC_RING = 'NC° Ring',
}

@Injectable({ providedIn: 'root' })
export class BackdoorDevicesService {
  private translateService = inject(TranslateService)
  private store = inject(SignalStore)
  private userLocale$ = toObservable(this.store.$userLocale)

  private testBuckets = [
    BackdoorTestBucket.test,
    BackdoorTestBucket.test2,
    BackdoorTestBucket.test3,
  ]

  private supportedCountries = ['US', 'GB']

  private bandPrices: Record<'US' | 'GB', Record<'full' | 'discounted', number>> = {
    US: {
      full: 99,
      discounted: 29,
    },
    GB: {
      full: 69,
      discounted: 19,
    },
  }

  private ringPrices: Record<'US' | 'GB', Record<'full' | 'discounted', number>> = {
    US: {
      full: 249,
      discounted: 179,
    },
    GB: {
      full: 159,
      discounted: 119,
    },
  }

  private bucketsWithBackdoorDevice = [BackdoorTestBucket.test2, BackdoorTestBucket.test3]

  public getBucket(): BackdoorTestBucket | undefined {
    const bucket = getBucket(this.store.$experiment().assignments[Experiment.DEVICE_BACKDOOR])
    return bucket as BackdoorTestBucket | undefined
  }

  public isParticipant(): boolean {
    return this.testBuckets.includes(this.getBucket()!)
  }

  public hasBackdoorDevice(): boolean {
    return this.bucketsWithBackdoorDevice.includes(this.getBucket()!)
  }

  public async injectBackdoorDevice(devices: EnrichedProduct[]): Promise<EnrichedProduct[]> {
    return await new Promise<EnrichedProduct[]>(resolve => {
      this.userLocale$.pipe(skipWhile(locale => !locale.country)).subscribe(locale => {
        const currentThermometer = devices.find(i => isThermometerKey(i.key))
        const isSupportedLocale = this.supportedCountries.includes(locale.country)

        // There should always be a thermometer, but we cannot risk it. If there is no thermometer, we should not inject the backdoor device.
        // We should always be in a supported country if we get here, but we cannot risk it. If we are not in a supported country, we should not inject the backdoor device.
        if (!currentThermometer || !isSupportedLocale) {
          if (!env.prod) {
            console.log(
              '[Backdoor Device Experiment] Not working? Are you geolocated in a supported country?',
            )
          }
          resolve(devices)
          return
        }

        const country = locale.country as 'US' | 'GB'
        const symbol = country === 'US' ? '$' : '£'

        const bucketToBackdoorDevice: Partial<
          Record<BackdoorTestBucket, Pick<EnrichedProduct, 'ui'>>
        > = {
          [BackdoorTestBucket.test2]: {
            ui: {
              title: BackdoorDevice.NC_BAND,
              subtitle: 'Wear it overnight',
              description: 'Save with the annual plan',
              price: `${symbol}${this.bandPrices[country]['discounted']}`,
              strikedPrice: `${symbol}${this.bandPrices[country]['full']}`,
            },
          },
          [BackdoorTestBucket.test3]: {
            ui: {
              title: BackdoorDevice.NC_RING,
              subtitle: 'Wear it overnight',
              description: 'Save with the annual plan',
              price: `${symbol}${this.ringPrices[country]['discounted']}`,
              strikedPrice: `${symbol}${this.ringPrices[country]['full']}`,
            },
          },
        }

        const backdoorDevice: EnrichedProduct = {
          ...currentThermometer,
          ...bucketToBackdoorDevice[this.getBucket()!],
        }

        devices.splice(1, 0, backdoorDevice)

        resolve(devices)
      })
    })
  }

  public mutateDevices(devices: EnrichedProduct[]): EnrichedProduct[] {
    const productToDescription: Partial<Record<ProductKey, string>> = {
      [ProductKey.THERMOMETER]: this.translateService.instant('txt-purchase-include'),
      [ProductKey.BT_THERMOMETER]: this.translateService.instant('txt-purchase-include'),
      [ProductKey.T3_THERMOMETER]: this.translateService.instant('txt-purchase-include'),
      [ProductKey.OURA_RING_DISCOUNT]: this.translateService.instant('txt-purchase-separate'),
      [ProductKey.APPLE_WATCH]: this.translateService.instant('txt-purchase-separate'),
    }

    return devices.map(device => {
      return {
        ...device,
        ui: {
          ...device.ui,
          // participants in the test (test, test2, test3) will see a different description on the measuring device card
          description: productToDescription[device.key]!,
        },
      }
    })
  }

  // Inspired and adapted from calculateSavingsPercentage in NCBackend3
  private getSavingTag(
    yearly: EnrichedProduct,
    plans: EnrichedProduct[],
    hardwarePriceAmount?: number,
    discountedHardwarePriceAmount?: number,
  ): string {
    const monthly = plans.find(i => i.key === ProductKey.MONTH)!
    const monthlyFor12months = monthly.price.amount * yearly.duration! + (hardwarePriceAmount ?? 0)
    const yearlyFor12months = yearly.price.amount + (discountedHardwarePriceAmount ?? 0)
    const savings = Math.floor((1 - yearlyFor12months / monthlyFor12months) * 100)
    const amount = _round(savings, 5)
    return `Save ${amount}%`
  }

  public async injectBackdoorIncludedItem(
    plans: EnrichedProduct[],
    backdoorDevice?: BackdoorDevice,
  ): Promise<EnrichedProduct[]> {
    return await new Promise<EnrichedProduct[]>(resolve => {
      this.userLocale$.pipe(skipWhile(locale => !locale.country)).subscribe(locale => {
        const isSupportedLocale = this.supportedCountries.includes(locale.country)

        if (!backdoorDevice || !isSupportedLocale) {
          if (!env.prod) {
            console.log(
              '[Backdoor Device Experiment] Not working? Are you geolocated in a supported country?',
            )
          }
          resolve(plans)
          return
        }

        const country = locale.country as 'US' | 'GB'
        const symbol = locale.country === 'US' ? '$' : '£'

        const result = plans.map(plan => {
          if (plan.key === ProductKey.YEAR && backdoorDevice === BackdoorDevice.NC_BAND) {
            plan.savingsTag = this.getSavingTag(
              plan,
              plans,
              this.bandPrices[country]['full'],
              this.bandPrices[country]['discounted'],
            )
            plan.includedItem = {
              ...plan.includedItem!,
              title: backdoorDevice,
              description: 'Save with the annual plan',
              price: `${symbol}${this.bandPrices[country]['discounted']}`,
              strikedPrice: `${symbol}${this.bandPrices[country]['full']}`,
            }
          } else if (plan.key === ProductKey.MONTH && backdoorDevice === BackdoorDevice.NC_BAND) {
            plan.includedItem = {
              ...plan.includedItem!,
              title: backdoorDevice,
              price: `${symbol}${this.bandPrices[country]['full']}`,
              optional: false,
              description: '',
            }
          } else if (plan.key === ProductKey.YEAR && backdoorDevice === BackdoorDevice.NC_RING) {
            plan.savingsTag = this.getSavingTag(
              plan,
              plans,
              this.ringPrices[country]['full'],
              this.ringPrices[country]['discounted'],
            )
            plan.includedItem = {
              ...plan.includedItem!,
              title: backdoorDevice,
              description: 'Save with the annual plan',
              price: `${symbol}${this.ringPrices[country]['discounted']}`,
              strikedPrice: `${symbol}${this.ringPrices[country]['full']}`,
            }
          } else if (plan.key === ProductKey.MONTH && backdoorDevice === BackdoorDevice.NC_RING) {
            plan.includedItem = {
              ...plan.includedItem!,
              title: backdoorDevice,
              price: `${symbol}${this.ringPrices[country]['full']}`,
              optional: false,
              description: '',
            }
          }

          return plan
        })

        resolve(result)
      })
    })
  }
}
